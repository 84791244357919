.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem 0;
}

.fontDarker {
  font-weight: 600;
  color: #111827;
}

@media screen and (min-width: 769px) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;
    padding: 0.6rem 1.1rem;
  }
}
