.rowData{
    display: flex;
    align-items: center;
}

.accord{
    margin-bottom: 10px;
    border-radius: 5px;
}

.sortDiv{
    display: flex;
    flex-direction: column;
}

.sortByStatus{
    display: flex;
    gap: 20px;
    align-items: center;
}

.sortTitle{
    font-family: comfortaa;
    
}


  
.btnDiv{
    display: flex;
    gap: 25px;
    justify-content: right;

  }



  .resultDiv{
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    margin-left: 0;
  }


  .statusResult{
    width: 150px;
    height: 50px;
    border: 1px solid rgb(195, 191, 191);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    font-family: comfortaa;
    gap: 10px;

  }

  .customerName{
    text-transform: uppercase;
  }


  .differBox{
    border: 1px solid #118c84;
    width: 200px;
    padding: 10px;
    display: flex;
    justify-content: center;
    background: #64CCC5;
    color: white;
    border-radius: 5px;
    font-family: comfortaa;
    transition: 0.5s;
  }

  .diffBoxMain{
    display: flex;
    gap: 25px;
    margin: 10px;
    margin-left: 0;
  }

  .differBox:hover{
    transform: translateY(-10px);
  }

  .center{
    display: flex;
    justify-content: center;
  }


  .actionCell{
    display: flex;
    align-items: center;
    gap: 5px;
  }